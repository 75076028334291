import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

function SentrySessionReplayController() {
  const location = useLocation();

  const customerSignupId = useMemo(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts.length > 2 && ['sign-up', 'self-sign-up'].some((path) => pathParts[1] === path)) {
      return pathParts[2];
    }
    return null;
  }, [location.pathname]);

  useEffect(() => {
    const replay = Sentry.getReplay();
    if (replay) {
      if (customerSignupId != null) {
        Sentry.setTag('customer-signup-id', customerSignupId);
        replay.start();
      } else {
        replay.stop();
      }
    }
  }, [customerSignupId]);

  return null;
}

export default SentrySessionReplayController;
